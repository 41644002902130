import React, {useState, useEffect} from 'react';
import {get, post} from "superagent";
import {MdOutlineCollections} from "react-icons/md";
import {useAuthValue} from "../../Auth/AuthContext";

interface CollectionListProps {
    userId: string;
    submissionId: string;
}

/**
 * Created by olsiodobashi on 11/8/23
 */
const CollectionList = ({userId, submissionId}: CollectionListProps) => {
    const [collections, setCollections] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [collectionUpdated, setCollectionUpdated] = useState(false);
    const currentUser: any = useAuthValue();

    useEffect(() => {
        setLoading(true);

        get(process.env.REACT_APP_API + `/me/collections`).set({token: currentUser?.accessToken}).then(result => {
            // console.log(result.body);
            setCollections(result.body);
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            setLoading(false);
        })
    }, [userId, collectionUpdated]);

    const handleSave = async (collection: any) => {
        setLoading(true);

        try {
            const result = await post(process.env.REACT_APP_API + '/collection/add').send({
                collectionId: collection.id,
                submissionId
            });

            if (result.body) {
                // console.log(result.body);
                setCollectionUpdated(curr => !curr);
            }
        } catch (err) {
            const error: any = err;

            console.log(error);
            alert(error.message);
        } finally {
            setLoading(false);
        }
    }

    return collections.length ?
        <div>
            {
                collections.map((collection, index) => (
                    <button className={
                        "flex text-left space-x-2 px-3 py-2 border border-gray-300 rounded-md mb-2 w-full hover:border-red-500 " + (collection.itemIds.includes(submissionId) ? " border-red-500" : "")
                    }
                            onClick={() => handleSave(collection)}
                            key={collection.id}>
                        {
                            collection.thumbnail && collection.thumbnail.length ?
                                <img src={collection.thumbnail} alt="" width={120} className={"rounded-md"}/>
                                : (
                                    <div className={"w-[120px] text-center border border-gray-300 rounded-md"}>
                                        <MdOutlineCollections color={"red"} size={36} className={"mx-auto my-3"}/>
                                    </div>
                                )
                        }

                        <div>
                            <span>{collection.title}</span>
                            {collection.description ? <p>{collection.description}</p> : null}
                            <div className={"text-sm text-gray-600"}>{collection.itemIds.length || 0} submission(s)
                            </div>
                        </div>
                    </button>
                ))
            }
        </div> : null
}

export default CollectionList;
