import React, {useState, useEffect, Fragment} from 'react';
import {Dialog, Transition} from "@headlessui/react";
import {post} from "superagent";
import {useAuthValue} from "../../Auth/AuthContext";

interface ReportSubmissionProps {
    isOpen: boolean;
    onClose: () => any;
    submissionId: string
}

/**
 * Created by olsiodobashi on 11/6/23
 */
const ReportSubmission = ({isOpen, onClose, submissionId}: ReportSubmissionProps) => {

    const [reason, setReason] = useState('');
    const [notes, setNotes] = useState('');
    const [loading, setLoading] = useState(false);

    const currentUser: any = useAuthValue();

    const handleReport = async () => {
        setLoading(true);

        if (!reason) {
            alert('Please select a reason for your report.');
            setLoading(false);
            return;
        }

        try {
            const result = await post(process.env.REACT_APP_API + '/report').send({
                submissionId,
                reason,
                notes,
                reporterId: currentUser.uid
            });

            if (result.body) {
                // console.log(result.body);
            }
        } catch (err) {
            const error: any = err;

            console.log(error);
            alert(error.message);
        } finally {
            setLoading(false);
            onClose();
        }
    }

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25"/>
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel
                                className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >
                                    Report submission
                                </Dialog.Title>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                        Please select a reason why you are reporting this submission.
                                    </p>
                                </div>

                                <div className={"space-y-4 my-4"}>
                                    <select className={"input"} name="reason" id="reasonSelect"
                                            onChange={e => setReason(e.target.value)}>
                                        <option value="">Select reason</option>
                                        <option value="spam">Spam</option>
                                        <option value="inappropriate">Inappropriate content</option>
                                        <option value="copyright">Trademark/Copyright violation</option>
                                    </select>

                                    <textarea className={"input"} onChange={(e) => setNotes(e.target.value)} cols={30}
                                              rows={5} placeholder={"Report notes"}></textarea>
                                </div>

                                <div className="mt-4 flex justify-end space-x-2">
                                    <button
                                        type="button"
                                        className="button-cancel"
                                        onClick={onClose}
                                        disabled={loading}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        className="button-primary"
                                        onClick={handleReport}
                                        disabled={loading}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}

export default ReportSubmission;
