import React, { useState } from 'react';
import { storage } from '../../core/firebase'; // Ensure you have firebaseConfig set up
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

interface ProfilePictureUploadProps {
    uid: string;
    onComplete: (url: string) => void;
}

function ProfilePictureUpload({ uid, onComplete }: ProfilePictureUploadProps) {
    const [progress, setProgress] = useState(0);

    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
        if (!file) return;

        const storageRef = ref(storage, `profile_pictures/${uid}/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on('state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgress(Math.ceil(progress));
            },
            (error) => {
                console.error('Upload failed:', error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    // console.log('File available at', downloadURL);
                    onComplete(downloadURL);
                    setProgress(0);
                });
            }
        );
    };

    return (
        <div className={"relative inline-block mx-auto mt-4"}>
            <label className={"button-primary-outlined"}>
                <span>{ progress > 0 && progress <= 100 ? "Uploading..." : "Change profile picture" }</span>
                <input className={"opacity-0 invisible absolute top-0 left-0 w-full h-full"} type="file" onChange={handleFileChange} disabled={progress > 0 && progress < 100} />
            </label>
        </div>
    );
}

export default ProfilePictureUpload;
