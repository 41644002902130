import React, {useState, useEffect} from 'react';
import ChallengeHeader from "../Components/ChallengeHeader";
import {get} from "superagent";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import {isCached, getCached, cache} from "../services/caching.service";
import ChallengeDropdown from "../Components/ChallengeDropdown";

const HomePage = () => {
    const [loading, setLoading] = useState(false);
    const [submissions, setSubmissions] = useState<any[]>([]);
    const [selectedChallengeFilter, setSelectedChallengeFilter] = useState('');
    const [loadMoreEnd, setLoadMoreEnd] = useState(false);

    useEffect(() => {
        const getSubmissions = async () => {
            setLoading(true);
            const endpoint = `/challenges?challengeId=${selectedChallengeFilter}`;

            if (isCached(endpoint)) {
                // @ts-ignore
                setSubmissions(getCached(endpoint));
                setLoading(false);
            } else {
                const result = await get(process.env.REACT_APP_API + endpoint);

                if (result.body) {
                    setSubmissions(result.body);
                    cache(endpoint, result.body);
                    setLoading(false);
                }
            }
        }

        getSubmissions();
    }, [selectedChallengeFilter]);

    const getNextPage = async (_: any) => {
        if (submissions.length === 0) {
            return;
        }

        const endpoint = '/challenges';
        const result = await get(process.env.REACT_APP_API + endpoint + `?nextPageId=${submissions![submissions!.length - 1].id}`);

        if (result.body) {
            if (result.body.length === 0) {
                setLoadMoreEnd(true);
                return;
            }

            // console.log(`Next page ID: ${result.body[result.body.length - 1].id}`);
            setSubmissions(curr => curr!.concat(result.body));
            // console.log(`Next page ID: ${result.body[result.body.length - 1].id}`);
        }
    }

    return (
        <>
            <Helmet>
                <title>Daily UI</title>
            </Helmet>

            <ChallengeHeader/>

            <div id="challengeDropdown" className={"container mx-auto text-right my-4 px-4"}>
                <div className="max-w-[200px] ml-auto">
                    <ChallengeDropdown defaultOptionLabel={'All'} selectedChallengeId={null}
                                       onChange={setSelectedChallengeFilter}/>
                </div>
            </div>

            <div className={"container mx-auto mt-4 px-4"}>
                {
                    submissions.length === 0 && loading ? (
                            <div className={"text-center"}>
                                <h2>Loading...</h2>
                            </div>
                        ) :
                        submissions.length === 0 ? (
                            <div className={"text-center"}>
                                <h2>No submissions found</h2>
                            </div>
                        ) : (
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4">
                                {
                                    submissions?.map(submission => (
                                        // TODO: Reusable component
                                        <Link to={`/submission/${submission.id}`} key={submission.id}
                                              className={"group relative"}>
                                            <div
                                                className={"absolute top-0 left-0 text-white text-lg p-3 pb-6 bg-gradient-to-b from-black to-transparent w-full rounded-t-lg transition-all opacity-0 group-hover:opacity-100"}>{submission.title}</div>
                                            <div
                                                className={"submission-card radius-lg overflow-hidden shadow-lg rounded-lg"}>
                                                <div className={"h-[250px] bg-cover bg-center bg-no-repeat"}
                                                     style={{backgroundImage: `url(${submission.thumbnail})`}}></div>
                                            </div>
                                        </Link>
                                    ))
                                }
                            </div>
                        )
                }

                <div className={"text-center mt-6"}>
                    {loadMoreEnd ? <p className={"text-gray-500"}>Reached end...</p> :
                        <button className={"button-primary-outlined"} onClick={getNextPage}>Load more...</button>}
                </div>
            </div>
        </>
    );
}

export default HomePage;
