import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import {get} from "superagent";

interface RelatedSubmissionsProps {
    ownerId: string;
    ownerDisplayName: string
}

/**
 * Created by olsiodobashi on 11/6/23
 */
const RelatedSubmissions = ({ ownerId, ownerDisplayName }: RelatedSubmissionsProps) => {
    const [relatedWork, setRelatedWork] = useState<any[]>([]);

    useEffect(() => {
        const getUserRelatedSubmissions = async () => {
            try {
                const result = await get(process.env.REACT_APP_API + `/user/${ownerId}/submissions?limit=3`);

                if (result.body) {
                    setRelatedWork(result.body);
                }
            } catch (err) {
                console.log(err);
            }
        }

        getUserRelatedSubmissions();
    }, [ownerId]);

    return relatedWork.length ? (
            <section className={"container mx-auto border-b border-gray-200 py-10 px-4"}>
                <h2 className={"text-2xl text-center mb-4"}>More by {ownerDisplayName}</h2>

                <div className={"grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4"}>
                    {
                        relatedWork.map(item => (
                            // TODO: Reusable component
                            <Link to={`/submission/${item.id}`} key={item.id}>
                                <div className={"item-card radius-lg overflow-hidden shadow-lg rounded-lg"}>
                                    <div className={"h-[250px] bg-cover bg-center bg-no-repeat"}
                                         style={{backgroundImage: `url(${item.thumbnail})`}}></div>
                                </div>
                            </Link>
                        ))
                    }
                </div>

                <div className="text-center mt-4">
                    <Link to={`/user/${ownerId}`} className={"button-primary-outlined"}>Visit profile</Link>
                </div>
            </section>
        ) : null
}

export default RelatedSubmissions;
