import React, {useState, Fragment} from 'react';
import {Dialog, Transition} from "@headlessui/react";
import {useAuthValue} from "../../Auth/AuthContext";
import AddEditCollection from "../../Components/Collections/AddEditCollection";
import CollectionList from "../../Components/Collections/CollectionList";

interface SaveSubmissionProps {
    isOpen: boolean;
    onClose: () => void;
    submissionId: string;
}

/**
 * Created by olsiodobashi on 11/7/23
 */
const CollectionsDialog = ({isOpen, onClose, submissionId}: SaveSubmissionProps) => {
    const [showNewCollectionForm, setShowNewCollectionForm] = useState(false);

    const currentUser: any = useAuthValue();

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25"/>
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel
                                className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >
                                    {showNewCollectionForm ? "Create a new collection" : "Add this submission to a collection"}
                                </Dialog.Title>

                                {
                                    showNewCollectionForm ? (
                                        <AddEditCollection userId={currentUser.uid} onHideForm={() => setShowNewCollectionForm(false)} />
                                    ) : (
                                        <section>
                                            <div className="my-4 flex justify-between">
                                                <h3>Collections</h3>

                                                <button className={"link active"}
                                                        onClick={() => setShowNewCollectionForm(true)}>Create a new
                                                    collection
                                                </button>
                                            </div>

                                            <CollectionList submissionId={submissionId} userId={currentUser?.uid} />

                                            <div className="mt-4 flex justify-end space-x-2">
                                                <button
                                                    type="button"
                                                    className="button-primary"
                                                    onClick={onClose}
                                                >
                                                    Done
                                                </button>
                                            </div>
                                        </section>
                                    )
                                }
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}

export default CollectionsDialog;
