import React from 'react';
import {Link} from "react-router-dom";
import {Menu} from '@headlessui/react';
import {signout} from '../core/firebase';
// @ts-ignore
import defaultUser from '../assets/default-user.svg';

interface UserDropdownProps {
    user: any;
}

const UserDropdown = ({user}: UserDropdownProps) => (
    <Menu>
        <Menu.Button>
            <div style={{ backgroundImage: `url(${localStorage.getItem('photoUrl') || defaultUser})` }} className={"w-[45px] h-[45px] rounded-full mx-auto bg-cover bg-center bg-no-repeat border border-gray-200"}></div>
        </Menu.Button>

        <Menu.Items
            className={"absolute flex flex-col top-full right-0 mt-2 bg-white rounded-md border border-gray-300 min-w-[130px]"}>
            <Menu.Item>
                <Link className={`px-4 py-1 block hover:bg-gray-200`} to={`/user/${user.uid}`}>
                    Profile
                </Link>
            </Menu.Item>

            <Menu.Item>
                <Link className={`px-4 py-1 block hover:bg-gray-200`} to={'/account'}>
                    Settings
                </Link>
            </Menu.Item>

            <Menu.Item>
                <button className={`px-4 py-1 block hover:bg-gray-200 text-left`} onClick={async () => await signout()}>
                    Logout
                </button>
            </Menu.Item>

        </Menu.Items>
    </Menu>
);

export default UserDropdown;
