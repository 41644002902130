import React from 'react';
// @ts-ignore
import noResultsSvg from '../assets/icons/no-results.svg';

interface NoResultsProps {
    message: string;
}

/**
 * Created by olsiodobashi on 11/27/23
 */
const NoResults = ({message}: NoResultsProps) => (
    <div className={"flex flex-col items-center mx-auto py-10"}>
        <img src={noResultsSvg} alt="No results to display" />
        <p>{message}</p>
    </div>
)

export default NoResults;
