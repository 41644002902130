import React, {useEffect, useState} from "react";
import {storage} from "../../core/firebase";
import {ref, getDownloadURL, uploadBytesResumable} from "firebase/storage";
import {PiImageLight, PiTrashLight} from "react-icons/pi";

interface ImageUploadProps {
    submissionId: string;
    image: File;
    isCover: boolean;
    onMakeCover: (image: File) => void;
    onRemoveImage: (image: File) => void;
    startUpload: boolean;
    onUploadComplete: (urls: string) => void;
}

/**
 * Component that handles multiple image uploads for the user challenge submission.
 *
 * @param submissionId
 * The ID of the submission currently being created/edited
 * @param existingImages
 * An array of image URLs if the user is editing the submission. Show view mode if not empty.
 * @param onUploadComplete
 * Event triggered once all images have completed uploading in Firebase.
 * Contains an array of `downloadURL`s
 *
 * @todo Is there a limit to how many images a submission can have?
 */
const ImageUpload = ({submissionId, image, isCover, onMakeCover, onRemoveImage, startUpload, onUploadComplete}: ImageUploadProps) => {
    const [progress, setProgress] = useState(0);
    const [downloadUrl, setDownloadUrl] = useState('');

    useEffect(() => {
        // console.log(startUpload)
        if (!startUpload || downloadUrl) return;

        const ext = image.name.substring(image.name.lastIndexOf('.') + 1, image.name.length);
        // console.log('file name: ', image.name);
        // console.log('ext: ', ext);

        const storageRef = ref(storage, `submissions/${submissionId}/${image.name}`);
        const uploadTask = uploadBytesResumable(storageRef, image);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded

                setProgress(Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100));

                switch (snapshot.state) {
                    case "paused":
                        console.log("Upload is paused");
                        break;
                    case "running":
                        console.log("Upload is running: ", Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100));
                        break;
                }
            },
            (error) => {
                console.log('There was an error uploading your image');
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    console.log("File available at", downloadURL);
                    setDownloadUrl(downloadURL);
                    onUploadComplete(downloadURL);
                });
            }
        );

    }, [startUpload]);

    return (
        <div className={"flex flex-col justify-between items-center h-full"}>
            <div
                className={"border border-gray-200 p-1 rounded-md mb-2 hover:border-red-500"}>
                <img className={"rounded-md"} src={downloadUrl || URL.createObjectURL(image)}
                     alt={image.name}/>
                {/*<span className={"text-sm break-all"}>{image.name}</span>*/}
            </div>

            <div className={"space-x-4 mt-auto"}>
                {progress > 0 && progress < 100 ? (
                    <div className={"border border-gray-200 p-1 rounded-lg"}>
                        <progress value={progress} max={100}
                                  className={"rounded-lg block overflow-hidden"}>{progress}%
                        </progress>
                    </div>
                ) : (
                    <>
                        <button onClick={() => onRemoveImage(image)} title={"Delete"}>
                            <PiTrashLight size={"25px"}/>
                        </button>
                        <button onClick={() => onMakeCover(image)}
                                title={"Make cover image"}>
                            {isCover ? null : <PiImageLight size={"25px"}/>}
                        </button>
                    </>
                )
                }
            </div>
        </div>
    );
};

export default ImageUpload;
