import React from 'react';
// @ts-ignore
import sDc from '../../assets/icons/sDc.svg';
// @ts-ignore
import sFc from '../../assets/icons/sFc.svg';
// @ts-ignore
import sIc from '../../assets/icons/sIc.svg';
// @ts-ignore
import sTc from '../../assets/icons/sTc.svg';
import {formatDate} from "../../utils/date-format";

const UserAbout = ({user}: any) => {
    const getImageSrc = (name: string) => {
        if (name.startsWith('D')) {
            return sDc;
        } else if (name.startsWith('F')) {
            return sFc;
        } else if (name.startsWith('I')) {
            return sIc;
        } else if (name.startsWith('T')) {
            return sTc;
        } else {
            return sDc;
        }
    }

    return (
        <div className={"flex space-x-4 px-4"}>
            <div className={"w-3/4"}>
                <p className={"font-semibold"}>Bio</p>
                <p>{user.bio || "No bio yet."}</p>
            </div>

            <div className={"w-1/4"}>
                <div>
                    <p className="font-semibold">Location</p>
                    <span>{user.location}</span>
                    <br/>
                    <p className="font-semibold mt-4">Member since</p>
                    <span>{formatDate(user.created)}</span>
                    <br/>
                    <p className="font-semibold mt-4">Social</p>
                    {
                        user.socialLinks && Array.isArray(user.socialLinks) ? (
                            <ul className={"flex space-x-4"}>
                                {
                                    user.socialLinks.map((link: any) => link.url ? (
                                        <li className={"my-2"} key={link.name}>
                                            <a href={link.url} target={"_blank"}
                                            rel={"noopener"}>
                                                <img src={getImageSrc(link.name)} alt={link.name} width={30} />
                                            </a>
                                        </li>
                                    ) : null)
                                }
                            </ul>
                        ) : null
                    }
                </div>
            </div>
        </div>
    );
}

export default UserAbout;
