import React, {useState, useEffect} from 'react';

//@ts-ignore
import twitterLogo from '../assets/icons/sT.svg';
//@ts-ignore
import instagramLogo from '../assets/icons/sI.svg';
//@ts-ignore
import logo from '../assets/dailyUI_dark.svg';
import {Link} from "react-router-dom";

const Footer = () => (
    <div className={"bg-zinc-800 px-4 py-6 mt-10"}>
        <div className="container mx-auto flex flex-col md:flex-row justify-between text-white items-start">
            <img src={logo} alt="" width={100} />

            <ul className={"space-y-3"}>
                <li>
                    <Link to={"/about"}>About</Link>
                </li>

                <li>
                    <Link to={"/resources"}>Learn</Link>
                </li>

                {/*<li>*/}
                {/*    <Link to={"/jobs"}>Job board</Link>*/}
                {/*</li>*/}
            </ul>

            <ul className={"space-y-3"}>
                {/*<li>*/}
                {/*    <Link to={'/'}>Become a Sponsor</Link>*/}
                {/*</li>*/}

                <li>
                    <Link to={'/legal/privacy-policy'}>Privacy Policy</Link>
                </li>

                <li>
                    <Link to={'/legal/terms'}>Terms of Service</Link>
                </li>
            </ul>

            <ul className="flex space-x-4 items-center">
                <li>
                    <a href="https://twitter.com/DailyUIApp" target="_blank" rel={"noopener"} title="Follow Daily UI on Twitter">
                        <img src={twitterLogo} alt="DailyUI - Twitter" width="30px" />
                    </a>
                </li>

                <li>
                    <a href="https://instagram.com/DailyUIApp" target="_blank" rel={"noopener"} title="Follow Daily UI on Instagram">
                        <img src={instagramLogo} alt="DailyUI - Instagram" width="30px" />
                    </a>
                </li>

                <li>
                    <a className="button-primary-outlined dark" title="Help improve Daily UI by filling out a short survey" target="_blank" rel={"noopener"} href="https://dailyui.typeform.com/to/JsxDK5rz">Feedback</a>
                </li>
            </ul>
        </div>
    </div>
)

export default Footer;
