import React, {useState, useEffect} from 'react';
import {get} from "superagent";
import {Link, useParams} from "react-router-dom";
import UserSubmissions from "./UserSubmissions";
import UserAbout from "./UserAbout";
import UserLikes from "./UserLikes";
import UserCollections from "./UserCollections";
//@ts-ignore
import defaultUser from '../../assets/default-user.svg';

const TABS = ['Work', 'Collections', 'Likes', 'About'];

const ProfilePage = () => {
    const {uid} = useParams();
    const [user, setUser] = useState<any>();
    const [errorStatus, setErrorStatus] = useState<number>(0);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        setErrorStatus(0)
        setLoading(true)
        const getUser = async () => {
            try {
                const result = await get(`${process.env.REACT_APP_API}/user/${uid}`);

                if (result.body) {
                    setUser(result.body);
                    // console.log(result.body)
                }
            } catch (error) {
                console.log(error)
                const err: any = error;
                setErrorStatus(err?.status);
            } finally {
                setLoading(false);
            }
        }

        getUser();
    }, [uid]);

    const renderTabContent = () => {
        switch (activeTab) {
            case 0:
                return <UserSubmissions user={user}/>;
            case 1:
                return <UserCollections user={user} />;
            case 2:
                return <UserLikes user={user} />;
            case 3:
                return <UserAbout user={user} />;
            default:
                return <UserSubmissions user={user} />
        }
    }

    // TODO Create error component
    if (errorStatus === 404) {
        return (
            <div className={"text-center"}>
                <h1 className={"text-2xl text-gray-500 font-semibold mb-4"}>User not found.</h1>
                <Link to={"/"} className={"button-primary"}>Home</Link>
            </div>
        )
    }

    if (errorStatus === 500) {
        return (
            <div className={"text-center"}>
                <h1 className={"text-2xl text-gray-500 font-semibold mb-4"}>Server error.</h1>
                <Link to={"/"} className={"button-primary"}>Home</Link>
            </div>
        )
    }


    // TODO Create loading component
    if (!user && loading) {
        return (
            <div className={"text-center"}>
                <h1 className={"text-2xl text-gray-500 font-semibold"}>Loading...</h1>
            </div>
        );
    }

    return (
        <div className={"container mx-auto mt-6"}>
            <header className={"text-center"}>
                <div style={{ backgroundImage: `url(${user?.photoUrl || defaultUser})` }} className={"w-[130px] h-[130px] rounded-full mx-auto bg-cover bg-center bg-no-repeat border border-gray-200"}></div>
                <h1 className={"font-semibold text-xl mt-1"}>{user.displayName}</h1>
            </header>

            <ul className={"flex justify-center mt-8"}>
                {
                    TABS.map((tab, i) => (
                        <li className={i === activeTab ? "border-b border-red-500" : ''} key={i}>
                            <button className={"px-4" + (i === activeTab ? " text-red-500" : '')} onClick={() => setActiveTab(i)}>{tab}</button>
                        </li>
                    ))
                }
            </ul>

            <section className="w-[1050px] max-w-full mx-auto pt-4">
                {renderTabContent()}
            </section>
        </div>
    );
}

export default ProfilePage;
