import React, {version} from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import reportWebVitals from './reportWebVitals';
import App from "./App";

import packageJson from '../package.json';

const root = ReactDOM.createRoot(document.getElementById('root') as any);
root.render(
    // @ts-ignore
    <div data-app-version={packageJson.version} data-react-version={version}>
        <React.StrictMode>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </React.StrictMode>
    </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
