import React, {useState, useEffect} from 'react';
import {get} from "superagent";
import {useAuthValue} from "../../Auth/AuthContext";

const UserCollections = ({user}: any) => {
    const [collections, setCollections] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [selectedCollection, setSelectedCollection] = useState<any>(null);

    useEffect(() => {
        if (!user) return;

        try {
            get(process.env.REACT_APP_API + `/user/${user.uid}/collections`).then(result => {
                if (result.body) {
                    setCollections(result.body);
                    // console.log(result.body)
                }
            }).catch(err => {
                console.log(err);
            });
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }, [user]);

    return selectedCollection ? (
        <div className={"max-w-full w-[550px] mx-auto"}>
            <button onClick={() => setSelectedCollection(null)}>Back</button>
            {
                <h2 className={"text-2xl font-semibold"}>{selectedCollection.title}</h2>
            }
        </div>
    ) : (
        <div className={"grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 px-4"}>
            {
                collections.length === 0 ? <p className={"text-gray-600 font-semibold"}>No collections found</p> :
                collections?.map(collection => (
                    <div
                        // onClick={() => setSelectedCollection(collection)}
                    >
                        <div
                            className={"rounded-lg shadow-md bg-cover bg-center w-full bg-no-repeat bg-gray-200"}
                            style={{
                                backgroundImage: `url(${collection.thumbnail})`,
                                height: 300
                            }}></div>
                        <h2 className={"text-lg font-semibold mt-1"}>{collection.title}</h2>
                        <small className={"text-gray-500"}>{collection.itemIds.length} shots</small>
                        {/*<br/>*/}
                        {/*<small className={"text-gray-500"}>Updated {collection.lastUpdated}</small>*/}
                    </div>
                ))
            }
            {/*<div>*/}
            {/*    <div*/}
            {/*        className={"rounded-lg shadow-lg border border-red-500 hover:border-red-600 relative"}*/}
            {/*        style={{*/}
            {/*            width: 300,*/}
            {/*            height: 300*/}
            {/*        }}>*/}
            {/*        <div className={"absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] text-center"}>*/}
            {/*            <img src={imgPlaceholder} alt="" className={"inline-block mb-1"} />*/}
            {/*            <p>Create collection</p>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
}

export default UserCollections;
