import React, {useState, useEffect} from 'react';
import Input from "../Shared/Input";
import {resetPassword} from "../core/firebase";
import {Link} from "react-router-dom";

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const handlePasswordReset = async (event: any) => {
        event.preventDefault();
        setLoading(true);

        if (!email) {
            return;
        }

        resetPassword(email).then(result => {
            alert('Please check your inbox for instructions on how to reset your password.');
        }).catch(err => {
            alert('Something went wrong trying to reset your password. Please try again later.');
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <div className={"container max-w-[350px] mx-auto border border-gray-200 rounded-md p-4 mt-0 md:mt-12"}>
            <h1 className="text-xl font-semibold text-center">Reset password</h1>

            <form className="grid grid-cols-1 gap-4" onSubmit={handlePasswordReset}>
                <div>
                    <Input label={"Email"} value={email} required={true} onValueChange={setEmail} />
                    <Link className={"link mt-2 inline-block"} to={'/login'}>Back to login</Link>
                </div>

                <button className={"button-primary w-full"} disabled={loading}>Submit</button>
            </form>
        </div>
    );
}

export default ForgotPassword;
