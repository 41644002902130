import React from 'react';
import {sendEmailVerification} from 'firebase/auth';
import {PiImageLight, PiWarning} from "react-icons/pi";

interface VerifyAccountPromptProps {
    user: any;
}

/**
 * VerifyAccount
 * Created on 12/15/23
 */
const VerifyAccountPrompt = ({user}: VerifyAccountPromptProps) => {
    const verifyAccount = async () => {
        sendEmailVerification(user).then(result => {
            // console.log(result);
            alert('Please check your inbox for the link to verify your account.');
        }).catch(err => {
            console.log('Could not send verification email. Please try again later.');
            console.log(err);
        });
    }

    return (
        <div className={"mb-4"}>
            <div className="bg-yellow-200 p-4 rounded-lg flex space-x-2">
                <PiWarning size={"25px"}/>
                <div>
                    <strong>Please verify your account</strong>
                    <p>
                        You cannot submit your work until you verify your account.&nbsp;
                        <button className={"link underline"} onClick={verifyAccount}>Verify account now</button>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default VerifyAccountPrompt;
