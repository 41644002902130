import React, {useState, useEffect} from 'react';
import {get} from "superagent";
import {useAuthValue} from "../../Auth/AuthContext";
import {Link} from "react-router-dom";

const UserLikes = ({user}: any) => {
    const currentUser: any = useAuthValue();
    const [submissions, setSubmissions] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!user) return;

        try {
            get(process.env.REACT_APP_API + `/user/${user.uid}/likes`).then(result => {
                if (result.body) {
                    setSubmissions(result.body);
                    // console.log(result.body)
                }
            });
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }, []);


    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 px-4">
            {
                submissions.length === 0 ?
                    <p className={"text-gray-600 font-semibold"}>No likes found</p> :
                    submissions?.map(submission => (
                        // TODO: Reusable component
                        <Link to={`/submission/${submission.id}`} key={submission.id}>
                            <div className={"submission-card radius-lg overflow-hidden shadow-lg rounded-lg"}>
                                <div className={"h-[250px] bg-cover bg-center bg-no-repeat"}
                                     style={{backgroundImage: `url(${submission.thumbnail})`}}></div>
                            </div>
                        </Link>
                    ))
            }
        </div>
    );
}

export default UserLikes;
