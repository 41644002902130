import React, {useState, useEffect} from 'react';
import {useAuthValue} from "../../Auth/AuthContext";
import Input from "../../Shared/Input";
import {get, patch, post} from "superagent";
import ProfilePictureUpdate from "./ProfilePictureUpdate";
// @ts-ignore
import defaultUser from '../../assets/default-user.svg';

const SettingsPage = () => {
    const [loading, setLoading] = useState(true);
    const [profilePicture, setProfilePicture] = useState('');
    const [state, setState] = useState({
        displayName: '',
        location: '',
        bio: '',
        website: ''
    });
    const [socialLinks, setSocialLinks] = useState([
        { name: 'Dribbble', url: '' },
        { name: 'Facebook', url: '' },
        { name: 'Instagram', url: '' },
        { name: 'Twitter', url: '' }
    ]);
    const [user, setUser] = useState<any>();
    const currentUser: any = useAuthValue();

    if (!currentUser) {
        window.location.href = '/';
    }

    useEffect(() => {
        get(process.env.REACT_APP_API + '/me').set({ token: currentUser.accessToken }).then(result => {
            setUser(result.body);
            setState({
                displayName: result.body.displayName,
                location: result.body.location,
                bio: result.body.bio,
                website: result.body.website
            });
            if (result.body.socialLinks) {
                setSocialLinks(result.body.socialLinks);
            }
            if (result.body.photoUrl) {
                setProfilePicture(result.body.photoUrl);
            }
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            setLoading(false);
        });
    }, [currentUser]);

    if (!user && loading) {
        return <p>Loading...</p>
    }

    const handleSocialLinkChange = (name: string, url: string, i: number) => {
        const updatedLink = Object.assign({}, socialLinks[i]);
        updatedLink.url = url;
        const newLinks = socialLinks.slice();
        newLinks[i] = updatedLink;
        setSocialLinks(newLinks);
    }

    const handleProfilePictureChange = (url: string) => {
        if (!url) {
            alert('Something went wrong while updating your profile picture. Please try again later.');
            return;
        }

        setProfilePicture(url);
        patch(process.env.REACT_APP_API + '/me').set({token: currentUser.accessToken}).send({photoUrl: url}).then(result => {
            // console.log(result);
            localStorage.setItem('photoUrl', url);
        }).catch(err => {
            console.log(err);
        });
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setLoading(true);

        try {
            const result = await patch(process.env.REACT_APP_API + '/me').set({token: currentUser.accessToken}).send({
                ...state,
                socialLinks
            });

            if (result.body) {
                // console.log(result.body);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className={"container mx-auto max-w-[550px] mt-10"}>
            <header className={"text-center mb-4"}>
                <div style={{ backgroundImage: `url(${profilePicture || defaultUser})` }} className={"w-[130px] h-[130px] rounded-full mx-auto bg-cover bg-center bg-no-repeat border border-gray-200"}></div>
                <ProfilePictureUpdate uid={currentUser.uid} onComplete={handleProfilePictureChange} />
            </header>

            <form className={"space-y-4"} onSubmit={handleSubmit}>
                <Input name={"displayName"} label={"Display name"} value={state.displayName} required={true} onValueChange={(value) => setState({
                    ...state,
                    displayName: value
                })} />
                <Input name={"location"} label={"Location"} value={state.location} required={false} onValueChange={(value) => setState({
                    ...state,
                    location: value
                })} />
                <Input name={"bio"} label={"Bio"} type={"textarea"} value={state.bio} required={false} onValueChange={(value) => setState({
                    ...state,
                    bio: value
                })} />

                <hr className={"my-6"} />

                <Input name={"website"} label={"Website"} value={state.website} required={false} onValueChange={(value) => setState({
                    ...state,
                    website: value
                })} />

                {
                    socialLinks.map((link, index) => (
                        <Input name={link.name} key={link.name} label={link.name} value={link.url} required={false} onValueChange={(value) => handleSocialLinkChange(link.name, value, index)} />
                    ))
                }

                <div className="text-right">
                    <button disabled={loading} className={"button-primary"}>{loading ? "Saving..." : "Save" }</button>
                </div>
            </form>
        </div>
    );
}

export default SettingsPage;
