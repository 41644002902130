import { initializeApp } from "firebase/app";
import { getAuth, signOut, sendPasswordResetEmail } from 'firebase/auth';
import { getStorage } from "firebase/storage";
import { doc, collection, getFirestore } from 'firebase/firestore';

export const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const storage = getStorage(app);
export const firestore = getFirestore(app);

export const signout = async () => {
    // await AsyncStorage.removeItem('user');
    localStorage.removeItem('user');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('token');
    localStorage.removeItem('photoUrl');
    await signOut(auth);
    window.location.href = '/';
}

export const resetPassword = (email) => {
    return sendPasswordResetEmail(auth, email);
}

export const newDoc = (collectionName) => doc(collection(firestore, collectionName));
