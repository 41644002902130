import React, {useState, useEffect} from 'react';
import {get} from "superagent";
// @ts-ignore
import Banner from '../assets/resources/banner.jpg';
import {LuExternalLink} from "react-icons/lu";

const Resources = () => {
    const [resources, setResources] = useState<any[]>();

    useEffect(() => {
        const getData = async () => {
            try {
                const result = await get(process.env.REACT_APP_API + '/resources');

                if (result.body) {
                    setResources(result.body);
                }
            } catch (err) {
                console.log(err);
            }
        }

        getData();
    }, []);

    return (
        <>
            <section className={"bg-gray-200 mb-10"}>
                <div className="container mx-auto flex items-center md:h-[450px] relative">
                    <div className={"relative md:w-2/3 z-20 py-8 px-4 md:px-0"}>
                        <h1 className={"text-3xl mb-4"}>Level up your design game and launch your career in the field of UX
                            Design!</h1>
                        <p className={"mb-6 text-lg"}>Unleash your inner creator with our design courses! Whether you're a seasoned
                            designer or
                            just doodling on napkins, we provide a curriculum that adapts to your experience level.
                            Dive into
                            a world where creativity has no bounds, and watch as your ideas spring to life. Enrich your
                            skills,
                            embrace new techniques, and embark on a journey where every lesson is a stepping stone to
                            your design masterpiece.</p>

                        <a className={"button-primary align-middle"} href="https://designlab.pxf.io/JzQKjN" target={"_blank"} rel="noreferrer">
                            <span>Learn more</span>
                            <LuExternalLink className={"inline-block ml-2"} />
                        </a>
                    </div>

                    <div className={"absolute w-1/2 right-0 hidden md:block overflow-hidden h-full"}>
                        <img src={Banner} alt={"Level up your design! - Banner"} title={"Level up your design"} />
                        <div className={"bg-gradient-to-r from-gray-200 to-gray-200 via-transparent h-full w-full absolute right-0 top-0 z-10"}></div>
                    </div>
                </div>
            </section>

            <div className={"container mx-auto px-4 md:px-0"}>
                <h2 className={"text-2xl font-semibold mb-2"}>Resources</h2>
                <section className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                    {
                        resources?.map((resource: any) => (
                            <div
                                key={resource.name}
                                className="border border-gray-400 rounded-md p-4 w-full mb-4">
                                <div className="card-body">
                                    <div className="flex items-center mb-3">
                                        {/*<img*/}
                                        {/*    className="mr-3"*/}
                                        {/*    src={require('../assets/resources/' + resource.logo)}*/}
                                        {/*    height="45px"*/}
                                        {/*    alt={resource.name}*/}
                                        {/*/>*/}
                                        <div className="text-2xl font-bold">{resource.name}</div>
                                    </div>

                                    <p>{resource.description}</p>

                                    <div className="flex items-center justify-between">
                                        <small className="text-gray-500">{resource.pricing}</small>
                                        <div className="text-right">
                                            {resource.learnMoreUrl &&
                                                <a className="link text-red-500 p-1" href={resource.learnMoreUrl} target="_blank" rel="noreferrer">Learn more...</a>}
                                            <a className="link text-red-500 p-1 ml-1" href={resource.url} target="_blank" rel="noreferrer">Visit</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </section>
            </div>
        </>
    );
}

export default Resources;
