import React, {useState, useEffect} from 'react';

const SignupSuccess = () => {
    const [, set] = useState<string>();

    useEffect(() => {
        const getData = async () => {
        }

        getData();
    }, []);


    return (
        <div>
            <h2>Hwllo, {'world'}</h2>
        </div>
    );
}

export default SignupSuccess;
