import React, {useState, useEffect} from 'react';

// @ts-ignore
import graph1 from '../assets/about/graph-1.svg';
// @ts-ignore
import graph2 from '../assets/about/graph-2.svg';
// @ts-ignore
import graph3 from '../assets/about/graph-3.svg';
// @ts-ignore
import graph4 from '../assets/about/graph-4.svg';

const AboutPage = () => {
    return (
        <div>
            <section className={"container max-w-[850px] mx-auto px-4 lg:px-0"}>
                <h1 className={"text-5xl font-serif font-semibold mb-6"}>Building better designers around the world.</h1>
                <p className={"text-lg"}>Experimenting, learning, and fun: this is what Daily UI is about. UI is the face of software and web
                    design. As designers, learning to make visually pleasing and easy-to-use interfaces is critical.
                    Daily UI aims to connect creators with the UI community while also providing prompts for you to
                    play, experiment and discover new skills.</p>
            </section>

            <section className={"bg-red-50 flex justify-center my-24 px-4 lg:px-0"}>
                <img src={graph1} alt="" className={"-my-10"} />
            </section>

            <section className={"container max-w-[850px] mx-auto md:flex items-center my-24 space-x-8 px-4 lg:px-0"}>
                <div className={"w-2/3"}>
                    <h2 className={"text-4xl font-serif font-semibold mb-4"}>Daily challenges</h2>
                    <p className={"text-lg"}>Check in daily to participate in fun projects! Follow the challenges and use your DailyUI profile to create your portfolio.</p>
                </div>
                <img src={graph2} alt="" width={370} />
            </section>

            <section className={"bg-red-50 py-6 px-4 lg:px-0"}>
                <div className={"container max-w-[850px] mx-auto md:flex items-center my-24 space-x-8"}>
                    <img src={graph3} alt="" width={370} />
                    <div>
                        <h2 className={"text-4xl font-serif font-semibold mb-4"}>Improve your skills</h2>
                        <p className={"text-lg"}>Complete challenges, build your online portfolio and see how your skills grow over time!</p>
                    </div>
                </div>
            </section>

            <section className={"container max-w-[850px] mx-auto md:flex items-center my-24 space-x-8 px-4 lg:px-0"}>
                <div>
                    <h2 className={"text-4xl font-serif font-semibold mb-4"}>Share with the community</h2>
                    <p className={"text-lg"}>Post your work on DailyUI.com for the chance to get featured and rewarded! (rewards coming soon)</p>
                </div>
                <img src={graph4} alt="" width={400} />
            </section>
        </div>
    );
}

export default AboutPage;
