/*
 * Daily UI - Caching Service
 *
 * @todo Add default expiration date to cached items
 */

const timeToExpire = 21600000; // 6h in milliseconds

export const isCached = (key) => {
    const sessionData = sessionStorage.getItem(key);
    const {data, expires} = sessionData ? JSON.parse(sessionData) : {data: null, expires: 0};

    if (!data) {
        return false;
    }

    return expires >= new Date().getTime();
}

export const getCached = (key) => {
    const sessionData = sessionStorage.getItem(key);
    const {data} = sessionData ? JSON.parse(sessionData) : { data: null };

    return data;
}

export const cache = (key, data) => {
    sessionStorage.setItem(key, JSON.stringify({
        endpoint: key,
        data,
        expires: new Date().getTime() + timeToExpire
    }))
}
