import React from 'react';

// @ts-ignore
import logo from '../assets/dailyUI.svg';
import {Link} from "react-router-dom";
import {useAuthValue} from "../Auth/AuthContext";
import UserDropdown from "./UserDropdown";

const Navigation = () => {
    const currentUser: any = useAuthValue();

    return (
        <nav className={"flex items-center justify-between px-4"}>
            <Link className={'flex flex-col h-[70px] overflow-y-hidden justify-center'} to={'/'}>
                <img className={"inline-block w-[130px]"} src={logo} alt="Home"/>
            </Link>
            <ul className={"flex items-center space-x-4"}>
                <li>
                    <Link className={"link"} to={'/resources'}>Learn</Link>
                </li>

                <li>
                    <a className={"link"} href={"https://blog.dailyui.com"}>Blog</a>
                </li>

                {/*<li>*/}
                {/*    <Link className={"link"} to={"/jobs"}>Job board</Link>*/}
                {/*</li>*/}

                <li>
                    <Link className={"link"} to={'/about'}>About</Link>
                </li>
            </ul>

            {
                currentUser?.uid ? (
                    <div className={'flex items-center space-x-4 relative z-40'}>
                        <Link to={"/add"} className={"button-primary"}>Upload</Link>
                        <UserDropdown user={currentUser} />
                    </div>
                ) : (
                    <div>
                        <Link className={"link"} to={'/login'}>Login</Link>
                    </div>
                )
            }
        </nav>
    );
}

export default Navigation;
