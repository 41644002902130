import React, {useState, useEffect} from 'react';

import {get, post} from "superagent";
import {Link, useParams} from "react-router-dom";
import {IoHeart, IoHeartOutline, IoInformationCircle, IoSaveOutline} from "react-icons/io5";
import SubmissionGallery from "./SubmissionGallery";
import RelatedSubmissions from "./RelatedSubmissions";
import ReportSubmission from "./ReportSubmission";
import {useAuthValue} from "../../Auth/AuthContext";
import CollectionsDialog from "./CollectionsDialog";

const SubmissionDetails = () => {
    const [submission, setSubmission] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [isReportModalOpen, setIsReportModalOpen] = useState(false);
    const [isSaveSubmissionModalOpen, setIsSaveSubmissionModalOpen] = useState(false);
    const [liked, setLiked] = useState(false);

    const currentUser: any = useAuthValue();
    const {submissionId} = useParams();

    useEffect(() => {
        const getSubmissionById = async () => {
            try {
                const result = await get(process.env.REACT_APP_API + '/submission/' + submissionId);

                if (result.body) {
                    // console.log(result.body);
                    setSubmission(result.body);
                }
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false);
            }
        }

        getSubmissionById();
    }, [submissionId]);

    useEffect(() => {
        const getIsLiked = async () => {
            if (!submissionId || !currentUser) return;

            const result: any = await get(process.env.REACT_APP_API + `/submission/${submissionId}/liked?userId=${currentUser?.uid}`);

            if (result.body) {
                setLiked(result.body.liked);
            }
        }

        getIsLiked();
    }, [submissionId, currentUser]);

    const handleLike = async () => {
        if (!currentUser) {
            alert('You must be logged in to perform this action.');
            return;
        }

        setLoading(true);

        try {
            const response = await post(process.env.REACT_APP_API + `/submission/like`).send({
                submissionId,
                userId: currentUser?.uid
            });
            setLiked(response.body.liked);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    if (!submission && loading) {
        return (
            <div className={"container mx-auto py-20"}>
                <p className={"text-center"}>Loading...</p>
            </div>
        );
    }

    return (
        <>
            <section className={"container mx-auto max-w-[1172px] px-4"}>
                <header className={"flex justify-between mb-5 items-end"}>
                    <div className={"flex space-x-3"}>
                        <img src={submission.ownerPhotoUrl} className={"rounded-full"} width={55} alt=""/>
                        <div>
                            <h1 className={"text-2xl font-semibold"}>{submission.title}</h1>
                            <h2>by <Link className={"text-red-500"}
                                         to={`/user/${submission.ownerId}`}>{submission.ownerDisplayName}</Link></h2>
                        </div>
                    </div>

                    <span className={"text-gray-500"}>Posted {submission.date}</span>
                </header>

                <SubmissionGallery imageList={submission.imageList}/>

                <div className={"flex justify-between items-center my-10 max-w-[800px] mx-auto"}>
                    <button className={"flex items-center"} onClick={() => setIsReportModalOpen(true)}>
                        <IoInformationCircle color={"gray"}/>
                        <span className={"text-[gray]"}>Report</span>
                    </button>

                    <div className={"flex space-x-4"}>
                        <button className={"flex items-center space-x-1"} onClick={() => handleLike()}>
                            {liked
                                ? <IoHeart size={20} color={"red"}/>
                                : <IoHeartOutline size={20} color={'black'}/>
                            }
                            <span className={liked ? 'text-red-500' : 'text-black'}>Like</span>
                        </button>

                        <button className={"flex items-center space-x-1"}
                                onClick={() => setIsSaveSubmissionModalOpen(true)}>
                            <IoSaveOutline size={20}/>
                            <span>Save</span>
                        </button>
                    </div>
                </div>
            </section>

            <section className={"container mx-auto border-y border-gray-200 py-10 px-4"}>
                <div className="max-w-[800px] mx-auto">
                    <div className={"flex justify-between"}>
                        {submission.description ? <p>{submission.description}</p> :
                            <p className={"text-gray-500"}>This item has no description.</p>}

                        <div className={"float-right max-w-[250px]"}>
                            <p>Tags</p>
                            <ul className={"flex flex-wrap"}>
                                {submission.tags?.map((tag: string) => (
                                    <li className={"border rounded-md px-2 py-1 mb-2 mr-2"} key={tag}>
                                        {/*<Link className={"link"} to={`/tag/${tag.toLowerCase()}`}>*/}
                                        <span>{tag}</span>
                                        {/*</Link>*/}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <RelatedSubmissions ownerId={submission.ownerId} ownerDisplayName={submission.ownerDisplayName}/>

            {
                submissionId &&
                (
                    <>
                        <ReportSubmission isOpen={isReportModalOpen && Boolean(currentUser)} onClose={() => setIsReportModalOpen(false)}
                                          submissionId={submissionId}/>
                        <CollectionsDialog isOpen={isSaveSubmissionModalOpen && Boolean(currentUser)}
                                           onClose={() => setIsSaveSubmissionModalOpen(false)}
                                           submissionId={submissionId}/>
                    </>
                )
            }

        </>
    );
}

export default SubmissionDetails;
