import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";

const NotFound = () => (
    <div className={"text-center py-10"}>
        <h1 className={"text-2xl text-gray-500 font-semibold mb-4"}>Page not found</h1>
        <Link to={"/"} className={"button-primary"}>Home</Link>
    </div>
)

export default NotFound;
