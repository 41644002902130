import React, {useState} from 'react';

const TermsOfService = () => (
    <div className={"container mx-auto"}>
        <h1 className={"text-3xl font-semibold mb-1"}>Terms of Service</h1>

        <p>These GENERAL TERMS OF SERVICE (thereinafter referred to as: “Terms of Service”) apply to EACH AND EVERY
            User visiting
            and/or using this Website, Content and/or Services. These Terms of Service are available on our Website.
            Along with the
            Privacy Policy, these Terms of Service constitute a single, legally binding document between you and
            Daily UI. By
            accessing and using https://dailyui.com, you are hereby compliant and expressly AGREE to all
            conditions contained
            in these Terms of Service, and thus find them reasonable. Furthermore, your behavior on this Website is
            at ALL times
            governed and subject to these Terms of Service, Privacy Policy and all auxiliary documents you are given
            access to, and
            thereby acquainted with.
        </p>

        <p>
            References in these documents to Daily UI, “(the) Service”, “We” or “Us” mean the Daily UI
            Website. References to
            “You”, “Your” and “User” mean the person, group or other entity utilizing Daily UI. By visiting and
            using
            Daily UI’s
            Website and/or Software, You are hereby compliant to all Provisions including the express terms and
            restrictive
            covenants these General Terms of Service and Daily UI’s other legal documents and/or notifies include,
            and
            thus find
            them to be reasonable. If you do not find these provisions reasonable, please stop using this Website
            and/or
            Services.
            Deletion of your User Account or Profile information is also required. Unless you do so, you WARRANT
            that
            you are
            compliant to these Terms of Service and provisions to which you are properly notified via Our Website.
            Furthermore, you
            hereby INDEMNIFY Daily UI, its owner Company, Subsidiaries, Offices, Partners, Employees, Management
            or
            Administrative
            Team, other Representatives and all future individuals or legal persons involved with Daily UI, in
            respect
            to any
            losses possibly suffered in connection to the Website, Software, Content and Services. Daily UI, being
            a
            private
            entity does NOT support intolerant behavior and does not promote any type of penal activity.
        </p>

        <h5 className={"text-xl font-semibold mt-4 mb-1"}>Eligibility</h5>
        <p>

            Users, which are at least 18 years of age or older may enter into this Agreement with the Service. You
            hereby represent
            and warrant for the truthfulness, reliability and accuracy of the information provided to the Service
            for
            the purposes
            of registration and account usage, as well as for any questions or required information regarding the
            offered services
            by the Service. You further represent that you are from sufficient legal age and capacity, at least 18
            years
            of age and
            competent to enter into this agreement.
        </p>

        <h5 className={"text-xl font-semibold mt-4 mb-1"}>Account Registration and Profile</h5>
        <p>
            Users, for the purposes of visiting and browsing our Website may or may not have a registered account.
            Please note that
            some aspects of functionality of Daily UI are to be limited without having a registered account. Upon
            registration,
            some personal information may be required from you. For the purposes of purchases, please see the
            “Purchasing. Orders
            and Payment” section. The personal information (thereinafter referred to as: “Registration Data”) that
            may
            be required
            from you is your contact details (such as, without limitation: name, e-mail address, shipping address,
            country/state,
            telephone number) and your billing details (such as, without limitation: the billing credentials as per
            your
            preferred
            way of purchasing services from Daily UI).
        </p>

        <h5 className={"text-xl font-semibold mt-4 mb-1"}>Disclaimer</h5>
        <p>

            The Daily UI Website, content and services are all provided on an “as is” and “as served” basis.
            Daily UI does not
            unilaterally provide any express or implied terms, representations, warranties or conditions.
            Daily UI,
            its employees,
            offices, agents or other representatives are further not liable, to the maximum extent permitted by law
            for
            any: direct,
            indirect, special or consequential loss; business or private losses, including: income, savings, or
            profit
            loss, loss of
            contract, loss of goodwill or representations loss, warranty loss, data corruption, personal loss. The
            aforementioned
            limitations are voided in the exclusive case that Daily UI has willingly voided its limitation of
            liability in a
            specific Set of Terms and/or Contract. Limitations apply in the case that Daily UI had been advised of
            the
            potential
            loss. Daily UI does not take any responsibility for the unreasonable behavior of visitors and/or third
            parties.
            Furthermore, Daily UI does not take any responsibility for publishing any content, which is not in any
            way
            meant to
            constitute and/or provide any type of professional advice. Daily UI is further not responsible in any
            way,
            to no
            extent in the unlikely case of severe host and/or server failure or breach, or any other Vis major,
            which is
            not in
            scope of Daily UI ability to control. This disclaimer cannot and will not exclude any warranty or
            liability implied by
            U.S., State of Connecticut Governing law, will not exclude and/or limit any warranty or liability which
            is
            illegal or
            unlawful to limit or exclude in compliance with U.S., State of Connecticut Governing law. You hereby
            understand this
            liability limitation statement, and are fully compliant with it in its entirety.
        </p>

        <h5 className={"text-xl font-semibold mt-4 mb-1"}>Usage Restrictions - Service’s reserved rights in case of infringement</h5>
        <p>
            You hereby agree that you are going to use this Website, Content and Services reasonably. You agree that
            you
            will
            withhold of any actions that can cause or could cause damage to Daily UI’s Website, Software, Content
            and/or Services.
            You and every person you authorize with, using Your Account may not engage in any activity which may
            result
            in lowering
            the overall quality of the Website and Services offered therein. You and other people authorized to use
            your
            respective
            User Account will also withhold of any activity that may cause nuisance, or in any other way prevent any
            other Users
            from using the provided Content and Services. Any and every person shall further withhold of any actions
            which are
            recognized by law as unlawful, illegal, fraudulent or harmful towards Daily UI’s Website, Software,
            Services or
            Functionality, and shall not, in any way promote this type of illegal activity, under the penalty of
            civil
            and penal
            provisions of the applicable US law. Daily UI’s Website, Content and/or Services are not to be used
            for
            distribution
            of Spyware, Trojan Horses, Root kits, Keystroke Loggers or any type of Malware whilst utilizing
            Daily UI,
            its Software
            and/or Services, under the penalty of the U.S. Governing civil and/or penal provisions. You and
            individuals
            Authorized
            to use Your User Account shall also withhold of any systematic or automated information and data
            collection
            activities,
            including data harvesting, limitation scrapping, data mining and other similar activities without the
            consent of the
            Website’s administrators under the penalty of privacy protection statutes under U.S. Governing law. Any
            consent issued
            by the Website’s administrators on data collection will be fully coherent with the U.S. Governing law
            and in
            compliance
            with this Website’s set of legal documents. In the event such consent is possible to issue, due to
            statutory
            provisions
            by U.S. Governing law, any eventual consent will be issued in paper form upon previous request, and as
            such
            will be
            subject to further notification of the Website’s visitors, customers and/or Users. Without the required
            form
            and
            previous notification of Users, any consent given by Daily UI in regards to data collection is null
            and
            void. This
            Website is not to be used for marketing purposes without the Website administrators’ consent. Such
            consent
            will be
            issued in proper form, in full compliance with the Privacy Policy and U.S. Governing law. One shall not
            transfer, sublet
            or otherwise dispose of their respective rights and obligations. You shall further not engage in
            unacceptable behavior,
            namely: - harassment, threatening or intimidating another User, - transmitting any unlawful,
            threatening,
            abusive,
            offensive, defamatory or hateful text or voice communication or images and/or other material, any
            ethnically
            or racially
            objectionable material, or any material infringing intellectual property, privacy and/or publicity
            rights of
            any third
            party, - using obscene or offensive User Names, - posting any material with the intention to solicit
            other
            Users, -
            permutation of usage of inappropriate language as to the room You are hosting, - abusing Your rights to
            take
            action
            against individuals logged into a specific room You are hosting, - disparaging Daily UI’s services.
            Daily UI reserves the right to terminate a User’s access to its Website or Software if, under
            appropriate
            circumstances, they are determined to be repeated Infringers of the aforementioned restrictions.
            Daily UI
            further
            reserves the right to decide, whether User Submissions of any kind are appropriate, and if they comply
            with
            these
            provisions. Daily UI reserves the right, but not the obligation to initiate personal inquiries of
            potential abuse
            and/or misbehavior personally or upon filed reports or complaints, regarding any Users’ activity. Any
            investigation or
            inquiry will be at the sole discretion of Daily UI and it will include any steps that Daily UI finds
            appropriate.
            Note that these steps may include disclosing User conduct to one or more entrusted parties. Sanctioned
            by
            U.S. Governing
            law, any misconduct and/or breach of the aforementioned usage provisions will be treated as seen fit by
            Daily UI under
            its sole discretion. Methods of preventing further damage or nuisance towards other Users may,
            Daily UI’s
            staff or
            other third parties towards which Users have committed a breach of their rights include: - Suspension or
            Termination of
            Your User Account with or without previous notification; - Temporary or permanent Bans from Our Websites
            with or without
            previous notification; Other methods Daily UI’s staff/administrators see fit. Further note that
            Daily UI, in dealing
            with specific breaches of these provisions, does exclude bringing up civil and/or penal legal action
            against
            an
            individual or group, whom or which have taken part in this type of behavior. Note that Daily UI may
            disclose Your
            personal information in the NECESSARY range under U.S. Governing law in connection with any current or
            future legal
            proceedings in order to constitute or defend its respective rights or in case Daily UI needs to comply
            with the
            demands (namely formal requests) of appropriate State authorities.
        </p>

        <h5 className={"text-xl font-semibold mt-4 mb-1"}>Third-Party Services, Defective performance of third-party Service providers</h5>
        <p>

            The Service allows you to gather data from third-party services and data sources, without exclusion to
            third-party
            websites. These Sources are used and utilized at the sole discretion of the Service, and the Service
            reserves the right
            to discontinue or change using such sources at any given time. The Service does not assume any liability
            from the data
            and/or content from such sources and services. Users have the sole responsibility of obtaining any
            consensus
            or
            authorization, if any is needed, for the usage of this Service or processing of the available data. The
            Service takes no
            responsibility for any information processing made on its behalf, as Users warrant that they have
            obtained
            all
            authorizations and/or consensus from the data sources for further usage of the provided data.
            This service may be used as an add-on to third-party services and software. We do not assume any
            liability
            for such
            third-party services and software, and you are solely and exclusively responsible for obtaining any
            licenses, consent or
            authorizations needed for the use of data.
            Third-party services and Websites may be made available throughout the Service. In order for you to use
            these
            third-party services, you must read and understand all usage restrictions and other Terms and Policies
            of
            the
            appropriate third-party service and/or website. This Service disclaims all liability arising out of
            defective
            performance of linked services and/or website, unless expressly assumed within this Agreement. Upon
            being
            prompted by a
            follow-up link to a third-party service and/or website, you are bound by a different set of Terms and
            Policies that
            govern the usage of the appropriate third-party service or website, and you further understand that you
            use
            the
            third-party service or website at your own risk. We recommend using due diligence and familiarizing
            yourself
            with the
            Terms and Policies prior to usage of the services you are prompted to. Please note that this Service
            does
            not endorse,
            promote or otherwise market the third-party websites and/or services, unless expressly and appropriately
            stated within
            our Website.
            This Service may contain ads and promotions from third parties. Your business dealings, correspondence
            or
            other contact
            made throughout these ads and promotions falls outside the Service’s scope of control, and, as such, the
            Service
            disclaims all liability arising from these dealings, contacts or correspondence.
        </p>

        <h5 className={"text-xl font-semibold mt-4 mb-1"}>Modifications of these Terms</h5>
        <p>

            In the event that Daily UI goes through a business transition (merger, acquisition, sale of assets
            etc.),
            your
            personal information and content uploaded by you will likely be among the assets transferred accordingly
            to
            the title of
            transfer under which the information and content are being owned or used by the Service (ex. If you, as
            a
            Creator, have
            licensed your submission to the Service in order to be displayed, this right will be transferred to the
            party included
            in the business transaction with Daily UI). You will be notified via e-mail of any such change of
            ownership of your
            personal information.
            Daily UI reserves the right to discontinue any portion of the whole service with or without previous
            notice.
            Daily UI is not to be held liable for such discontinuance. Any concluded sales will survive the
            discontinuance of
            these Terms of Service, and are to be executed on behalf of Daily UI. Every older version of these
            documents is
            superseded with the issuing of a renewed version. In case of change of our General Terms of Service or
            Privacy Policy,
            we will notify you on this Web page, by e-mail, or by means of notice on our Home Page. These Terms,
            along
            with all
            supplemental documents you have expressed your consent to, without limitation to conclusive means of
            acceptance, are to
            be regarded as a single, legally-binding contract between you and Daily UI.
        </p>

        <h5 className={"text-xl font-semibold mt-4 mb-1"}>Limitation of Liability</h5>
        <p>

            You hereby agree to indemnify and hold Daily UI, it’s owner Company, Representatives and Partners
            harmless
            from and
            against any loss, cost, damage and expense, including but not limited to: attorneys' fees and court
            costs,
            arising
            directly or indirectly from Your use of the Daily UI’s Website, software or services and/or Your
            breach of
            any
            representation, warranty or restriction contained in this agreement.
            You expressly agree that you shall withhold of class action suits against the Service, and that all (if
            any)
            proceedings
            against the Service occur, such shall be handled on an individual basis, based on rules established and
            agreed to in
            these Terms of Service.
            If any lawsuits are to occur, you agree that your action will not supersede the amount of 100 USD, or
            the
            expenses made
            whilst using this Website, whichever is greater.
        </p>

        <h5 className={"text-xl font-semibold mt-4 mb-1"}>Disclaimer</h5>
        <p>

            Daily UI’s website, content and services are all provided “as is”. Daily UI does not unilaterally
            provide any
            express or implied terms, representations, warranties or conditions. Daily UI, its employees, offices,
            agents or other
            representatives are further not liable, to the maximum extent permitted by law for any: direct,
            indirect,
            special or
            consequential loss; business or private losses, including: income, savings, or profit loss, loss of
            contract, loss of
            goodwill or representations loss, warranty loss, data corruption, personal loss. The aforementioned
            limitations are
            voided in the exclusive case that Daily UI has willingly voided it’s limitation of liability in a
            specific
            Set of
            Terms and/or Contract. Limitations apply in the case that Daily UI had been advised of the potential
            loss.
            Daily UI
            does not take any responsibility for the unreasonable behavior of visitors and/or third parties.
            Furthermore, Daily UI
            does not take any responsibility for publishing any content, which is not in any way meant to constitute
            and/or provide
            any type of professional advice. Daily UI is further not responsible in any way, to no extent in the
            unlikely case of
            severe host and/or server failure or breach, or any other Vis major, which is not in scope of
            Daily UI’s
            ability to
            control. This disclaimer cannot and will not exclude any warranty or liability implied by U.S. Governing
            law, will not
            exclude and/or limit any warranty or liability which is illegal or unlawful to limit or exclude in
            compliance with U.S.
            Governing law. You hereby understand this liability limitation statement, and are fully compliant with
            it in
            its
            entirety.
        </p>

        <h5 className={"text-xl font-semibold mt-4 mb-1"}>Dispute Resolution</h5>
        <p>

            If any provision of these Terms of Service is, or is to be found by an appropriate authority,
            unenforceable
            under U.S.
            Governing law, that will not affect the enforceability of any other provisions of these Terms of
            Service.
            These Terms
            are written in compliance with U.S. Governing law and international standards. The Parties agree to
            solve
            all disputes
            or controversies, including those arising from termination of employment in a manner other than filing
            court
            actions.
            Alternative dispute-resolution prevents statute barring of the rights and obligations arising from this
            Agreement. If
            these dispute-resolving methods prove unsuccessful, they are to commence court proceedings. If any
            provision
            of this
            Agreement is, or is to be found by an appropriate authority, unenforceable under governing law, that
            will
            not affect the
            enforceability of any other provisions of this Agreement. Statutory provisions will govern all aspects
            of
            General
            Service Agreement, which have not been regulated by the provisions of this agreement, by U.S. law.
        </p>

        <h5 className={"text-xl font-semibold mt-4 mb-1"}>Jurisdiction and Tribunal</h5>
        <p>

            The parties covenant that the applicable and usable law according to which this agreement will be
            governed
            will be the
            law of U.S. Subsequently to the aforementioned, U.S., State of Connecticut Law will regulate all
            disputes or
            litigation
            arising from the content of this contract or its execution, accordingly. The parties agree that the
            competent court
            shall be the court designated under U.S., State of Connecticut law, in accordance with its statutory
            provisions.
        </p>

        <h5 className={"text-xl font-semibold mt-4 mb-1"}>Other Contract provisions. Interpretation Clauses</h5>
        <p>

            The Service’s failure to act in a particular circumstance does not waive the ability to act in respect
            to
            the specific
            circumstance or circumstances similar to it. If any provision of these Terms is rendered invalid by
            statutory
            provisions, interpretation of law or its application, rendered unenforceable, it shall not affect the
            remainder of these
            terms. The remaining terms shall stay in full force and effect. Section headings are made for
            convenience.
            They do not
            have to correspond with the content of the appropriate section and do not have any legal effect. This
            agreement is not
            to be in favor of a weaker party, as there is not such party under this specific contract. Due to the
            fact
            that both the
            Service and the Users have high bargaining powers in stipulating a contract with each other, as well as
            several dozens
            of options for customizing the user experience, we believe that this agreement, even if it is a main
            framework for the
            functioning of this Service, is a collection of the basic rules and guidelines all Services of this type
            abide by.
        </p>

        <h5 className={"text-xl font-semibold mt-4 mb-1"}>Termination of this Agreement</h5>
        <p>

            Termination of this Agreement may occur immediately as per the Service’s reserved rights in this
            Agreement.
            Termination
            of this Agreement on your behalf expressly means discontinuance of any subscription plans and paid
            services
            and deletion
            of your user profile. Please note that no refunds are issued for the unused prepaid paid services, and
            no
            renewals of
            subscriptions occur in the case you decide to re-register with our service. All due obligations of the
            Service to the
            Users shall be waived once this Agreement is terminated on the User’s behalf.
        </p>

        <h5 className={"text-xl font-semibold mt-4 mb-1"}>Feedback, Questions and Contact</h5>
        <p>
            Providing feedback may be possible by any given means within the Service. All feedback given in the form
            of
            user
            comments, blog posts or similar feeds shall be available to the World Wide Web. Please refrain from
            using
            any obscenity
            or profanity, as the Service reserves all discretional rights as per this Agreement.
            All questions or other inquiries made directly to the service may be sent at the designated e-mail
            address
            in the Contact section of our Website.
        </p>
    </div>
);

export default TermsOfService;
