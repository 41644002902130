import React, {useState, useEffect} from 'react';
import {get} from "superagent";
import {Link} from "react-router-dom";
import NoResults from "../../Components/NoResults";

interface UserSubmissionsProps {
    user: any;
}

/**
 * Created by olsiodobashi on 11/9/23
 */
const UserSubmissions = ({user}: UserSubmissionsProps) => {
    const [submissions, setSubmissions] = useState<any[]>([]);

    useEffect(() => {
        if (!user) return;

        try {
            get(process.env.REACT_APP_API + `/user/${user?.uid}/submissions`).then(result => {
                if (result.body) {
                    setSubmissions(result.body);
                }
            });
        } catch (err) {
            console.log(err);
        }
    }, [user]);

    return (
        submissions.length ? (
                <div className={"grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4"}>
                    {
                        submissions.map((submission: any) => (
                            // TODO: Reusable component
                            <Link to={`/submission/${submission.id}`} key={submission.id}>
                                <div className={"submission-card radius-lg overflow-hidden shadow-lg rounded-lg"}>
                                    <div className={"h-[250px] bg-cover bg-center bg-no-repeat"}
                                         style={{backgroundImage: `url(${submission.thumbnail})`}}></div>
                                </div>
                            </Link>
                        ))
                    }
                </div>
            ) : <NoResults message={"User has no submissions yet."}/>
    );
}

export default UserSubmissions;
