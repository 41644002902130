import React, {useState, useEffect} from 'react';

interface SubmissionGalleryProps {
    imageList: string[];
}

const SubmissionGallery = ({ imageList }: SubmissionGalleryProps) => {
    const [activeImageIndex, setActiveImageIndex] = useState<number>(0);

    useEffect(() => {
        setActiveImageIndex(0);
    }, [imageList]);

    return (
        <div className={"flex flex-col md:flex-row justify-between space-y-2 md:space-x-2 md:space-y-0"}>
            <div className={"w-full"}>
                <img src={imageList[activeImageIndex]} alt="" className={"rounded-lg mx-auto"}/>
            </div>

            <ul className={"space-y-2"}>
                {imageList.map((src: string, index: number) => (
                    <li>
                        <button
                            className={"border rounded-lg p-1" + (activeImageIndex === index ? " border-red-500" : "")}
                            onClick={() => setActiveImageIndex(index)}
                        >
                            <img src={src} alt="" width={200} className={"rounded-lg"}/>
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default SubmissionGallery;
