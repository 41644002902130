import React, {useState, useEffect} from 'react';
import {post} from "superagent";
import Input from "../../Shared/Input";

interface AddEditCollectionProps {
    userId: string;
    onHideForm: () => void;
}

/**
 * Created by olsiodobashi on 11/8/23
 */
const AddEditCollection = ({ userId, onHideForm }: AddEditCollectionProps) => {

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [isPrivate, setIsPrivate] = useState(false);

    const [loading, setLoading] = useState(false);

    const handleCreateCollection = async () => {
        setLoading(true);

        try {
            const result = await post(process.env.REACT_APP_API + '/collection').set({ UserID: userId }).send({
                title, description, isPrivate,
                ownerId: userId
            });

            if (result.body) {
                setTitle('');
                setDescription('');
                setIsPrivate(false);

                onHideForm();
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    return (
        <section>
            <div className={"mt-4"}>
                <Input label={"Title"} value={title} required={true} onValueChange={setTitle} />
                <Input label={"Description"} value={description} required={false} onValueChange={setDescription} type={"textarea"} />

                <label className={"align-middle space-x-1"}>
                    <input type="checkbox" checked={isPrivate} onChange={(e) => setIsPrivate(e.target.checked)} />
                    <span>Private?</span>
                </label>
            </div>

            <div className="mt-4 flex justify-between space-x-2">
                <button className={"button-cancel"} onClick={() => onHideForm()}>Back</button>
                <button
                    type="button"
                    className="button-primary"
                    onClick={handleCreateCollection}
                    disabled={loading}
                >
                    Save
                </button>
            </div>
        </section>
    );
}

export default AddEditCollection;
