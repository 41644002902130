import React, {useState, useEffect} from 'react';
import Input from "../Shared/Input";
import {Link} from "react-router-dom";
import {post} from "superagent";
import {signInWithEmailAndPassword} from "firebase/auth";
import {auth} from "../core/firebase";

const SignupPage = () => {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [error, setError] = useState('');


    const handleSignup = async (e: any) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            alert('Passwords do not match!');
            return;
        }

        post(process.env.REACT_APP_API + '/user').send({
            email, displayName, password
        }).then(async response => {
            await signInWithEmailAndPassword(auth, email, password);
            window.location.href = '/signup-success';
        }).catch(err => {
            // TODO Must handle errors better in case when the user/email already exists
            setError('The server encountered an error while creating the user');
        });
    }

    return (
        <div className={"container max-w-[350px] mx-auto border border-gray-200 rounded-md p-4 mt-0 md:mt-12"}>
            <h1 className="text-xl font-semibold text-center">Sign up</h1>

            <form className="grid grid-cols-1 gap-4" onSubmit={handleSignup}>
                <Input label={"Display name"} value={displayName} required={true} onValueChange={setDisplayName} />
                <Input label={"Email"} value={email} required={true} onValueChange={setEmail} />
                <Input label={"Password"} type={"password"} value={password} required={true} onValueChange={setPassword} />
                <Input label={"Confirm password"} type={"password"} value={confirmPassword} required={true} onValueChange={setConfirmPassword} />
                <Link className={"link mt-1 inline-block"} to={'/login'}>Back to login</Link>

                {error ? <p className={"text-red-600 font-semibold"}>{error}</p> : null }
                <button className={"button-primary w-full"} disabled={loading}>Submit</button>
            </form>
        </div>
    );
}

export default SignupPage;
