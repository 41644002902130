import React, {useState, useEffect} from 'react';

interface ButtonProps {
    label: string;
    loading: boolean;
    type: "button"|"submit"|"reset"|undefined;
}

/**
 * Button
 * Created on 12/5/23
 */
const Button = ({label, loading, type}: ButtonProps) => (
    <button className={"button-primary"} disabled={loading} type={type}>{loading ? "Loading..." : label}</button>
)

export default Button;
