import React from 'react';

const PrivacyPolicy = () => (
    <div className={"container mx-auto"}>
        <h1 className={"text-3xl font-semibold mb-1"}>Privacy Policy - DailyUI.com</h1>
        <p>This privacy notice discloses the privacy practices for <a href="http://DailyUI.com">DailyUI.com</a> and
            our
            website; <a href="http://www.dailyui.com">http://www.dailyui.com</a>. This privacy notice applies solely
            to
            information collected by this website, except where stated otherwise. It will notify you of the
            following:</p>
        <ul>
            <li>What information we collect;
            </li>
            <li>With whom it is shared;
            </li>
            <li>How it can be corrected;
            </li>
            <li>How it is secured;
            </li>
            <li>How policy changes will be communicated; and
            </li>
            <li>How to address concerns over misuse of personal data.
            </li>
        </ul>

        <h5 className={"text-xl font-semibold mt-4 mb-1"}>Information Collection, Use, and Sharing</h5>
        <p>We are the sole owners of the information collected on this site. We only have access to/collect
            information that
            you voluntarily give us via email or other direct contact from you. We will not sell or rent this
            information to
            anyone.</p>
        <p>We will use your information to respond to you, regarding the reason you contacted us. We will not share
            your
            information with any third party outside of our organization, other than as necessary to fulfill your
            request,
            e.g., to ship an order.</p>
        <p>Unless you ask us not to, we may contact you via email in the future to tell you about specials, new
            products or
            services, or changes to this privacy policy.</p>

        <h5 className={"text-xl font-semibold mt-4 mb-1"}>Your Access to and Control Over Information</h5>
        <p>You may opt out of any future contacts from us at any time. You can do the following at any time by
            contacting us
            via the email address provided on our website:</p>
        <ul>
            <li>See what data we have about you, if any.
            </li>
            <li>Change/correct any data we have about you.
            </li>
            <li>Have us delete any data we have about you.
            </li>
            <li>Express any concern you have about our use of your data
            </li>
        </ul>

        <h5 className={"text-xl font-semibold mt-4 mb-1"}>Registration</h5>
        <p>In order to use this website, a user must first complete the registration process. During registration a
            user is
            required to give certain information (such as name and email address). This information is used to
            contact you
            about the products/services on our site in which you have expressed interest. At your option, you may
            also
            provide demographic information (such as gender or age) about yourself, but it is not required.</p>

        <h5 className={"text-xl font-semibold mt-4 mb-1"}>Security</h5>
        <p>We take precautions to protect your information. When you submit sensitive information via the website,
            your
            information is protected both online and offline.</p>
        <p>Wherever we collect sensitive information (such as credit card data), that information is encrypted and
            transmitted to us in a secure way. You can verify this by looking for a closed lock icon or the “https”
            at the
            beginning of the address of the web page.</p>
        <p>While we use encryption to protect sensitive information transmitted online, we also protect your
            information
            offline. Only employees who need the information to perform a specific job (e.g. billing or customer
            service)
            are granted access to personally identifiable information. The computers/servers on which we store
            personally
            identifiable information are kept in a secure environment.</p>

        <h5 className={"text-xl font-semibold mt-4 mb-1"}>Cookies</h5>
        <p>We use “cookies” on this site. A cookie is a piece of data stored on a site visitor’s hard drive to help
            us
            improve your access to our site and identify repeat visitors to our site. For instance, when we use a
            cookie to
            identify you, you would not have to log in with a password more than once, thereby saving time while on
            our
            site. Usage of a cookie is in no way linked to any personally identifiable information on our site.</p>

        <h5 className={"text-xl font-semibold mt-4 mb-1"}>Surveys & Contests</h5>
        <p>From time-to-time our site requests information via surveys or contests. Participation in these surveys
            or
            contests is completely voluntary and you may choose whether or not to participate and therefore disclose
            this
            information. Information requested may include contact information (such as name and shipping address),
            and
            demographic information (such as zip code, age level). Contact information will be used to notify the
            winners
            and award prizes. Survey information will be used for purposes of monitoring or improving the use and
            satisfaction of this site.</p>

        <h5 className={"text-xl font-semibold mt-4 mb-1"}>Notification of Changes</h5>
        <p>Whenever material changes are made to the privacy notice specify how you will notify consumers.</p>

        <h5 className={"text-xl font-semibold mt-4 mb-1"}>Other Provisions as Required by Law</h5>
        <p>Numerous other provisions and/or practices may be required as a result of laws, international treaties,
            or
            industry practices. It is up to you to determine what additional practices must be followed and/or what
            additional disclosures are required. Please take special notice of the California Online Privacy
            Protection Act
            (CalOPPA), which is frequently amended and now includes a disclosure requirement for “Do Not Track”
            signals.</p>

        <p>If you feel that we are not abiding by this privacy policy, you should contact us immediately via
            email at <a href="mailto:contact@dailyui.com">contact@dailyui.com</a>.</p>
    </div>
);

export default PrivacyPolicy;
