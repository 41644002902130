import React, {useState, useEffect} from 'react';
import {get} from 'superagent'
// @ts-ignore
import challengeBg from '../assets/hero.jpg'
import {Link} from "react-router-dom";
import {isCached, getCached, cache} from "../services/caching.service";
import {useAuthValue} from "../Auth/AuthContext";

const ChallengeHeader = () => {
    const [challenge, setChallenge] = useState<any>();
    const currentUser = useAuthValue();

    useEffect(() => {
        const getData = async () => {
            const endpoint = '/challengesList/today';

            if (isCached(endpoint)) {
                // @ts-ignore
                setChallenge(getCached(endpoint));
            } else {
                try {
                    // console.log('calling ', process.env.REACT_APP_API)
                    const result = await get(process.env.REACT_APP_API + endpoint);

                    if (result.body) {
                        setChallenge(result.body);
                        cache(endpoint, result.body);
                    }
                } catch (err) {
                    console.log(err)
                }
            }
        }

        getData();
    }, []);

    // TODO If no challenge for the day, show generic challenge
    if (!challenge) {
        return <div></div>
    }

    return (
        <div className={'px-4 md:px-0 overflow-hidden'}>
            <div className={"px-8 shadow-lg py-16 relative bg-no-repeat bg-cover"}
                 style={{backgroundImage: `url(${challengeBg})`}}>
                <div className={"absolute left-0 top-0 w-full h-full bg-gradient-to-r from-gray-200"}></div>
                <div className={"container mx-auto relative z-5 w-full lg:w-1/2"}>
                    <h1 className={"text-xl mb-1"}>Daily UI Challenges: Boost Your Design Skills</h1>
                    <h2 className={'uppercase text-gray-500'}>Today's challenge</h2>
                    <h2 className={'text-3xl mb-2'}>{challenge?.title}</h2>
                    <p>{challenge?.description}</p>


                    {
                        currentUser ? (
                            <Link to={`add?challengeId=${challenge?.id}`}
                            className={"button-primary mt-4"}>Participate</Link>
                        ) : <button onClick={() => alert('You must be logged in to participate in daily challenges.')} className={"button-primary mt-4"}>Participate</button>
                    }
                </div>
            </div>
        </div>
    );
}

export default ChallengeHeader;
