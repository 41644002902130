import React, {useState, useEffect} from 'react';
import {Link, useSearchParams} from "react-router-dom";
import Input from '../../Shared/Input';
import ImageUpload from "./ImageUpload";
// @ts-ignore
import uploadSvg from '../../assets/image-placeholder.svg';
import {newDoc} from "../../core/firebase";
import ChallengeDropdown from "../../Components/ChallengeDropdown";
import {post} from "superagent";
import VerifyAccountPrompt from "../../Auth/VerifyAccountPrompt";
import {useAuthValue} from "../../Auth/AuthContext";
import {serverTimestamp} from 'firebase/firestore';

const AddSubmission = () => {
    const currentUser: any = useAuthValue();
    const [searchParams, setSearchParams] = useSearchParams();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [challengeId, setChallengeId] = useState('');
    const [tags, setTags] = useState<string[]>([]);

    const [startUpload, setStartUpload] = useState(false);

    const [images, setImages] = useState<any[]>([]);
    const [downloadUrls, setDownloadUrls] = useState<string[]>([]);

    // Generate a new document reference whose ID will be used to store the document
    // The backend ensures the ID does not exist since the docRef gets a new reference everytime a user reloads the page
    const docRef = newDoc('challenges');

    /**
     * Starts the upload process for the selected images. The `useEffect` keeps track when all upload components have finished their task
     * and sends the submission data to the API.
     */
    const handleSubmit = (event: any) => {
        event.preventDefault();

        if (!currentUser.emailVerified) {
            return alert('Please verify your account: You cannot submit your work until you verify your account.');
        }

        setStartUpload(true);
    }

    const onImageUploadComplete = async (url: string) => {
        setDownloadUrls((curr: string[]) => {
            curr.push(url);
            return curr;
        });

        if (startUpload && downloadUrls.length === images.length) {
            await createSubmission();
        }
    }

    const createSubmission = async () => {
        try {
            const result = await post(process.env.REACT_APP_API + `/submission/${docRef.id}`).send({
                // dates
                createdAt: new Date(),
                lastUpdated: new Date().getTime(),
                date: new Date().toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric'
                }),

                // misc
                colorPalette: null,
                views: 0,

                // info
                title,
                description,
                challengeId,
                tags,
                docId: docRef.id,
                imageList: downloadUrls,
                thumbnail: downloadUrls[0],
                thumbnailStoragePath: `submissions/${docRef.id}_${images[0].name}`,
                // owner
                ownerId: currentUser.uid,
                ownerDisplayName: currentUser.displayName,
                ownerPhotoUrl: currentUser.photoURL
            });

            if (result.body) {
                Object.keys(sessionStorage).filter(key => key.startsWith('/challenge')).forEach(key => {
                    sessionStorage.removeItem(key);
                });

                window.location.href = '/';
            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleFileChange = (e: any) => {
        if (!e.target.files.length) {
            console.log('Input empty');
        }

        // const selectedFiles = [...images, ...Array.from(e.target.files)].slice(0, 4);

        setImages(curr => [...curr, ...Array.from(e.target.files)].slice(0, 4));
    }

    const removeImage = (file: any) => {
        setImages(current => current.filter(item => item.name !== file.name));
    }

    /**
     * Moves the image to the top of the images array
     */
    const makeCover = (file: any) => {
        setImages(curr => {
            curr = curr.filter(item => item.name !== file.name);
            curr.unshift(file);

            return curr;
        });
    }

    return (
        <div className={"container lg:max-w-[70%] max-w-none mx-auto px-8 lg:px-0 pb-10"}>

            {!currentUser.emailVerified ? <VerifyAccountPrompt user={currentUser}/> : null}
            <h1 className={"text-2xl"}>Publish your work</h1>

            <div className={"flex lg:space-x-4 flex-wrap lg:flex-nowrap"}>
                <div className={"w-full lg:w-2/3"}>
                    <div>
                        <div
                            className={"w-full border-2 border-gray-200 border-dashed rounded-md relative overflow-hidden transition-all hover:border-red-500 " + (images.length ? "h-[150px]" : "h-[300px]")}
                        >
                            <input onChange={handleFileChange} type="file"
                                   disabled={!currentUser.emailVerified}
                                   className={"absolute border border-red-400 w-full h-full opacity-0 cursor-pointer z-10"}
                                   multiple={true} accept={"image/png, image/jpeg, image/jpg"}
                            />
                            <div className={"flex flex-col items-center justify-center h-full cursor-pointer"}>
                                <img src={uploadSvg} alt="Upload input icon" className={"mb-2"}/>
                                <small>Click here to add images</small>
                            </div>
                            <small className={"float-right mt-1"}>Recommended image ratio <strong>4:3</strong></small>
                        </div>

                        {
                            images.length ?
                                <ul className={"mt-4 grid grid-cols-4 gap-4"}>
                                    {
                                        images.map((image, i) => (
                                            <li key={i + '_' + image.name}>
                                                <ImageUpload image={image} submissionId={docRef.id}
                                                             isCover={i === 0}
                                                             onMakeCover={makeCover} onRemoveImage={removeImage}
                                                             onUploadComplete={onImageUploadComplete}
                                                             startUpload={startUpload}/>
                                            </li>
                                        ))
                                    }
                                </ul>
                                : null
                        }
                    </div>
                </div>

                <form className={"w-full lg:w-1/3 space-y-4 mt:10 lg:-mt-[28px]"}>
                    <Input type={"text"} label={"Title"} value={title} required={true} onValueChange={setTitle}/>
                    <Input type={"textarea"} label={"Description"} value={description} required={false}
                           onValueChange={setDescription}/>

                    <label className={"mt-1"}>
                        <span className={"text-sm"}>Challenge</span>
                        <ChallengeDropdown defaultOptionLabel={'Select challenge'}
                                           selectedChallengeId={searchParams.get('challengeId')}
                                           onChange={setChallengeId}/>
                    </label>

                    <Input type={"text"} label={"Tags"} value={tags} required={false}
                           onValueChange={val => setTags(val.split(', '))}/>
                </form>
            </div>

            {currentUser.emailVerified ? (
                <div className={"flex items-center justify-end space-x-6 mt-10"}>
                    <Link className={"text-center"} to={"/"}>Cancel</Link>
                    <button onClick={handleSubmit} className={"button-primary w-[100px]"} type={"submit"}
                            disabled={!currentUser.emailVerified}>Submit
                    </button>
                </div>
            ) : null
            }
        </div>
    );
}

export default AddSubmission;
