import React, {useState} from 'react';
import {Helmet} from 'react-helmet';
import {Link} from 'react-router-dom';
import {get} from 'superagent';
// @ts-ignore
import googleLogo from '../assets/google-logo.svg';

import {signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider} from 'firebase/auth';
import {auth} from '../core/firebase';
import Input from "../Shared/Input";
import Button from "../Shared/Button";

const LoginPage = ({className = ''}) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleLogin = async (event: any) => {
        event.preventDefault();

        setError('');
        setLoading(true);

        try {
            const credentials: any = await signInWithEmailAndPassword(auth, email, password);
            const loggedInUser = await get(`${process.env.REACT_APP_API}/me`).set('token', credentials.user.accessToken);

            if (loggedInUser.body) {
                localStorage.setItem('accessToken', credentials.user.accessToken);
                localStorage.setItem('user', JSON.stringify(loggedInUser.body));
            }

            window.location.href = '/';
        } catch (error) {
            console.log(error);
            setError('Could not log user in. Please try again later.')
        } finally {
            setLoading(false);
        }
    };

    const handleGoogleSignin = async () => {
        try {
            const result = await signInWithPopup(auth, new GoogleAuthProvider());
            const credential: any = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;

            localStorage.setItem('accessToken', token);
            localStorage.setItem('user', JSON.stringify(result.user));
            window.location.href = '/';
        } catch (err) {
            console.log(err);
            console.log('Could not sign in using provider. Please try again.');
        }
    }

    return (
        <div>
            <Helmet>
                <title>Login - Daily UI</title>
            </Helmet>
            <div className="container max-w-[350px] mx-auto border border-gray-200 rounded-md p-4 mt-0 md:mt-12">
                <div className="max-w-md mx-auto space-y-6">
                    <h1 className="text-xl font-semibold text-center">Login</h1>
                    <form className="grid grid-cols-1 gap-4" action="#" method="post" onSubmit={handleLogin}>
                        <Input label={"Email"} value={email} onValueChange={setEmail} required={true} />
                        <Input label={"Password"} value={password} required={true} type={"password"} onValueChange={setPassword} />

                        { error ? <p className={"text-red-600 font-semibold"}>{error}</p> : null }

                        {/*loading={loading}*/}
                        {/*<button className={"button-primary"} type="submit">Log in</button>*/}
                        <Button label={"Log in"} loading={loading} type={"submit"} />
                    </form>

                    <div className="block text-center">
                        New user? <Link className={"link"} to="/signup">Create an account</Link>
                        <br/>
                        <Link className={"link"} to={"/forgot-password"}>Forgot password?</Link>
                    </div>
                </div>

                <div>
                    <button className={"text-sm bg-neutral-100 border border-gray-200 w-full py-2 rounded-full mt-4 flex justify-center items-center space-x-2 transition-colors hover:bg-neutral-200"} onClick={() => handleGoogleSignin()}>
                        <img src={googleLogo} alt={"Google logo"} className={"w-[25px]"} />
                        <span>Login with Google</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
