import React, {useState, useEffect} from "react";
import './App.css';
import {Routes, Route, useNavigation} from 'react-router-dom';
import Navigation from "./Components/Navigation";
import HomePage from "./Pages/HomePage";
import AboutPage from "./Pages/About";
import AddSubmission from "./Pages/Submission/AddSubmission";
import {AuthProvider} from "./Auth/AuthContext";
import {onAuthStateChanged} from 'firebase/auth';
import {auth} from './core/firebase';
import LoginPage from "./Auth/Login";
import SignupPage from "./Auth/Signup";
import ProfilePage from "./Pages/User/ProfilePage";
import Footer from "./Components/Footer";
import ResourcesPage from "./Pages/Resources";
import TermsOfService from "./Docs/TermsOfService";
import PrivacyPolicy from "./Docs/PrivacyPolicy";
import ForgotPassword from "./Auth/ForgotPassword";
import SignupSuccess from "./Auth/SignupSuccess";
import SubmissionDetails from "./Pages/Submission/SubmissionDetails";
import JobsPage from "./Pages/Jobs";
import SettingsPage from "./Pages/User/SettingsPage";
import {get} from "superagent";
import NotFound from "./Pages/NotFound";

function App() {
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        onAuthStateChanged(auth, async (user: any) => {
            // console.log(user)
            if (user) {
                await user.reload();
                const idTokenResult = await user.getIdTokenResult(true);

                const firestoreUser = await get(process.env.REACT_APP_API + '/me').set({token: idTokenResult.token})
                if (firestoreUser.body && firestoreUser.body.photoUrl) {
                    localStorage.setItem('photoUrl', firestoreUser.body.photoUrl);
                }

                setCurrentUser(user);
            }
        });
    }, []);

    // if (process.env.REACT_APP_ENV === "dev") {
    //     return (
    //         <>
    //             <AuthProvider value={currentUser}>
    //                 <Navigation/>
    //                 <main>
    //                     <Routes>
    //                         <Route path={"*"} element={<NotFound />} />
    //                     </Routes>
    //                 </main>
    //             </AuthProvider>
    //         </>
    //     )
    // } else {
        return (
            <>
                <AuthProvider value={currentUser}>
                    <Navigation/>
                    <main>
                        <Routes>
                            <Route path="/" element={<HomePage/>}/>
                            <Route path="/about" element={<AboutPage/>}/>
                            <Route path={"/resources"} element={<ResourcesPage/>}/>
                            <Route path={"/jobs"} element={<JobsPage/>}/>
                            <Route path="/submission/:submissionId" element={<SubmissionDetails/>}/>

                            {/*<Route path="/tag/:tagId" element={<SubmissionDetails />}/>*/}

                            <Route path="/legal/terms" element={<TermsOfService/>}/>
                            <Route path="/legal/privacy-policy" element={<PrivacyPolicy/>}/>
                            <Route path="/user/:uid" element={<ProfilePage/>}/>

                            {
                                !currentUser ? (
                                    <>
                                        <Route path="/login" element={<LoginPage/>}/>
                                        <Route path="/signup" element={<SignupPage/>}/>
                                        <Route path="/signup-success" element={<SignupSuccess/>}/>
                                        <Route path="/forgot-password" element={<ForgotPassword/>}/>
                                    </>
                                ) : (
                                    <>
                                        {/*<Route path="/me" element={<ProfilePage/>}/>*/}
                                        <Route path="/account" element={<SettingsPage/>}/>
                                        <Route path="/add" element={<AddSubmission/>}/>
                                        <Route path="/edit/:challengeId" element={<AddSubmission/>}/>

                                    </>
                                )
                            }

                            <Route path={"*"} element={<NotFound/>}/>

                        </Routes>
                    </main>
                    <Footer/>
                </AuthProvider>
            </>
        );
    // }
}

export default App;
