import React, {useState, useEffect} from 'react';
import {get} from 'superagent';

/**
 * Created by olsiodobashi on 11/4/23
 */
const JobsPage = () => {
    const [jobs, setJobs] = useState<any[]>([]);

    useEffect(() => {
        const getData = async () => {
            try {
                const result = await get(process.env.REACT_APP_API + '/jobs');

                if (result.body) {
                    setJobs(result.body);
                }
            } catch (err) {
                console.log(err)
            }
        }

        getData();
    }, []);


    return (
        <div className={"container mx-auto"}>
            <h1 className={"text-3xl font-semibold"}>Job board</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab architecto distinctio labore mollitia neque
                non nostrum obcaecati quod? Architecto at autem consequuntur cumque deserunt, distinctio iusto
                perferendis ratione. Nam, ullam!</p>

            {
                jobs?.map((job: any, index: number) => (
                    <div>
                        {job.title}
                    </div>
                ))
            }
        </div>
    );
}

export default JobsPage;
