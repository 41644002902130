import React, {useState, useEffect} from 'react';

interface InputProps {
    label: string;
    value: any;
    required: boolean;
    onValueChange: (val: string) => any
    type?: string;
    name?: string;
}

const Input = ({name, label, value, type, required, onValueChange}: InputProps) => {
    const [error, setError] = useState('');

    const handleChange = (event: any) => {
        onValueChange(event.target.value);

        if (!event.target.value && required) {
            setError("Field is required");
            return;
        } else {
            setError('');
        }
    }

    return (
        <>
            <label className="block">
                <span className="text-sm required">{label}{required && <span>*</span>}</span>
                {
                    type === "textarea" ? (
                        <textarea name={name} className={"input mt-1 " + (error && !value ? '!border-red-400' : '')} cols={30} rows={5} value={value} onChange={handleChange}></textarea>
                    ) : (
                        <input type={type || "text"} placeholder={label} name={name}
                               className={"input mt-1 " + (error && !value ? '!border-red-400' : '')}
                               value={value}
                               onChange={handleChange}/>
                    )
                }
            </label>

            {error ? <span className={"text-red-700 font-semibold"}>{error}</span> : null}
        </>
    );
}

export default Input;
