import React, {useState, useEffect} from 'react';
import {cache, isCached, getCached} from "../services/caching.service";
import {get} from "superagent";

interface ChallengeDropdownProps {
    defaultOptionLabel: string;
    selectedChallengeId: string|null;
    onChange: (challengeId: string) => void
}

/**
 * Created by olsiodobashi on 11/4/23
 */
const ChallengeDropdown = ({ defaultOptionLabel, selectedChallengeId, onChange }: ChallengeDropdownProps) => {
    const [challengesList, setChallengesList] = useState([]);

    useEffect(() => {
        const getChallengeList = async () => {
            const endpoint = '/challengesList';

            if (isCached(endpoint)) {
                // @ts-ignore
                setChallengesList(getCached(endpoint));
            } else {
                const result = await get(process.env.REACT_APP_API + endpoint);

                if (result.body) {
                    setChallengesList(result.body);
                    cache(endpoint, result.body);
                }
            }
        }

        getChallengeList();
    }, []);

    return (
        <select className={"input"} defaultValue={selectedChallengeId || ''} onChange={(e) => onChange(e.target.value)}>
            <option value={''}>{defaultOptionLabel}</option>
            {
                challengesList.map((challenge: any, index: number) => (
                    <option value={challenge.id} key={index}>{challenge.title}</option>
                ))
            }
        </select>
    );
}

export default ChallengeDropdown;
